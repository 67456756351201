<template>
  <div class="devices-list-wrapper">
    <b-card no-body>
      <b-tabs v-model="tabIndex" card>
        <b-tab :title="$t('label.desktop')" active>
          <b-table :items="desktopList" :fields="fields" show-empty>
            <template #cell(device_uuid)="{ item }">
              {{ maskUuid(item.device_uuid) }}
            </template>
            <template #cell(actions)="{ item }">
              <b-button
                v-if="!isActiveDevice(item.device_uuid)"
                variant="light-danger"
                @click="confirmDelete(item.id)"
              >
                {{ $t('btn.delete') }}
              </b-button>
              <span v-else id="desktop-delete-btn" class="d-inline-block" tabindex="0">
                <b-button
                  variant="light-danger"
                  style="pointer-events: none;"
                  disabled
                >
                  {{ $t('btn.delete') }}
                </b-button>
              </span>
              <b-tooltip target="desktop-delete-btn">{{ $t('help.cant_delete_current_session') }}</b-tooltip>
            </template>
          </b-table>
        </b-tab>
        <b-tab :title="$t('label.smartphone')">
          <b-table :items="mobileList" :fields="fields" show-empty>
            <template #cell(device_uuid)="{ item }">
              {{ maskUuid(item.device_uuid) }}
            </template>
            <template #cell(actions)="{ item }">
              <b-button
                v-if="!isActiveDevice(item.device_uuid)"
                variant="light-danger"
                @click="confirmDelete(item.id)"
              >
                {{ $t('btn.delete') }}
              </b-button>
              <span v-else id="mobile-delete-btn" class="d-inline-block" tabindex="0">
                <b-button
                  variant="light-danger"
                  style="pointer-events: none;"
                  disabled
                >
                  {{ $t('btn.delete') }}
                </b-button>
              </span>
              <b-tooltip target="mobile-delete-btn">{{ $t('help.cant_delete_current_session') }}</b-tooltip>
            </template>
          </b-table>
        </b-tab>
      </b-tabs>
    </b-card>

    <b-modal id="deleteDeviceModal" centered hide-header hide-footer @hidden="hideModal">
      <div class="text-center my-4">{{ $t('modal.confirm_device_delete') }}</div>
      <div class="d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-hover-bg-danger btn-text-danger btn-hover-text-white border-0 font-weight-bold mr-2"
          @click="hideModal"
        >
          {{ $t('btn.cancel') }}
        </button>
        <button
          type="button"
          class="btn btn-hover-bg-success btn-text-success btn-hover-text-white border-0 font-weight-bold"
          @click="deleteDevice"
        >
          {{ $t('btn.confirm') }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getDeviceUuid } from "@/core/services/local-storage.service";

const DELETE_DEVICE_MODAL_ID = 'deleteDeviceModal';

export default {
  data() {
    return {
      tabIndex: 0,
      selectedDeviceId: null,
      fields: [
        { key: 'device_uuid', label: 'UUID' },
        { key: 'platform', label: this.$t('label.os_system') },
        { key: 'device', label: this.$t('label.device') },
        { key: 'first_login', label: this.$t('label.first_login') },
        { key: 'last_login', label: this.$t('label.last_login') },
        { key: 'actions', label: '' }
      ]
    }
  },
  beforeMount() {
    this.getList();
  },
  computed: {
    ...mapGetters({
      desktopList: 'devicesStore/DESKTOP_LIST',
      mobileList: 'devicesStore/MOBILE_LIST',
    })
  },
  methods: {
    getList() {
      this.$store.dispatch('devicesStore/GET_LIST');
    },
    isActiveDevice(deviceUuid) {
      return getDeviceUuid() === deviceUuid;
    },
    confirmDelete(id) {
      if (!id) return;
      this.$bvModal.show(DELETE_DEVICE_MODAL_ID);
      this.selectedDeviceId = id;
    },
    async deleteDevice() {
      const deviceId = this.selectedDeviceId;
      this.hideModal();
      try {
        await this.$store.dispatch('devicesStore/DELETE_DEVICE', deviceId);
        this.getList();
      } catch(e) {}
    },
    maskUuid(uuid) {
      if (!uuid) return '-';
      return uuid.slice(0, 4) + '****' + uuid.slice(-4);
    },
    hideModal() {
      this.selectedDeviceId = null;
      this.$bvModal.hide(DELETE_DEVICE_MODAL_ID);
    }
  }
}
</script>

<style lang="scss" scoped>
.devices-list-wrapper::v-deep {
  .table {
    td {
      vertical-align: middle;
    }
  }
}
</style>